import LinkButton from '@core/components/linkbutton/LinkButton';

import NotFoundImage from 'assets/images/not-found-2.png';

import classes from 'styles/notfound.module.scss';

const NotFound = () => {
	return (
		<section className={`${classes['not-found-container']} container mb-3 my-md-5 my-xl-5`}>
			<img src={NotFoundImage.src} alt='404' />
			{/* <h1>Page Not Found</h1> */}
			<p>
				The page you were looking for could not be found. It might have been removed, renamed, or
				did not exist in the first place.
			</p>
			<LinkButton className={classes['action']} kind="primary-color" to="/">
				Go to Homepage
			</LinkButton>
		</section>
	);
};

export default NotFound;
